import React, { useState } from "react";

const Clients = () => {
  const [showAdditionalItems, setShowAdditionalItems] = useState(true);

  const handleButtonClick = () => {
    setShowAdditionalItems(!showAdditionalItems);
  };
  return (
    <div className="pt-2 pb-5">
      <h2 class="fs-16 text-uppercase span-text mb-3 text-center">
        Our Customers
      </h2>
      <p class=" mb-10 text-center">
        Join our growing community of satisfied clients who have experienced the
        transformative power of innovation with Tietech. Your vision is our
        priority, and we're here to turn it into reality.
      </p>
      <div className="row mb-4">
        <div className="col-lg-4" data-aos="fade-up" data-aos-duration="1000">
          <div className="card-clients mb-3 mb-lg-0">
            <img width={100} src="/img/euc.png" alt="logoss" />
            <h1 className="p-3">Estate Upcare</h1>
          </div>
        </div>
        <div className="col-lg-4" data-aos="fade-up" data-aos-duration="2000">
          <div className="card-clients  mb-3 mb-lg-0">
            <img width={100} src="/img/shipnobi.png" alt="logoss" />
            <h1 className="p-3">Shibnobi Wallet & Chrome Extension</h1>
          </div>
        </div>
        <div className="col-lg-4" data-aos="fade-up" data-aos-duration="3000">
          <div className="card-clients  mb-3 mb-lg-0">
            <img width={100} src="/img/Oman.png" alt="logoss" />
            <h1 className="p-3">Oman Alzhemer's Society</h1>
          </div>
        </div>
      </div>
      {!showAdditionalItems && (
        <div className="row justify-content-center">
          <div className="col-lg-4" data-aos="fade-up" data-aos-duration="3000">
            <div className="card-clients  mb-3 mb-lg-0">
              <img width={100} src="/img/smg-logo.png" alt="logoss" />
              <h1 className="p-3">SMG</h1>
            </div>
          </div>
          <div className="col-lg-4" data-aos="fade-up" data-aos-duration="3000">
            <div className="card-clients  mb-3 mb-lg-0">
              <img width={100} src="/img/Fabi.png" alt="logoss" />
              <h1 className="p-3">Fabi Capital Ltd</h1>
            </div>
          </div>
          <div className="col-lg-4" data-aos="fade-up" data-aos-duration="3000">
            <div className="card-clients  mb-3 mb-lg-0">
              <img width={100} src="/img/SGC.png" alt="logoss" />
              <h1 className="p-3">Secured Gold Coin</h1>
            </div>
          </div>
          <div className="col-lg-4 pt-4" data-aos="fade-up" data-aos-duration="3000">
            <div className="card-clients  mb-3 mb-lg-0">
              <img width={100} src="/img/SGCPay.png" alt="logoss" />
              <h1 className="p-3">SGC PAY</h1>
            </div>
          </div>
        </div>
      )}
      <div className="text-center pt-4">
        <button className="btn custom-btn" onClick={handleButtonClick}>
          {showAdditionalItems ? "Show More" : "Show Less"}
        </button>
      </div>
    </div>
  );
};

export default Clients;
