import React from "react";
import { InlineWidget } from "react-calendly";

const Appointment = () => {
  
  return (
    <div>
      <div>
        <div class="progress-wrap">
          <svg
            class="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"></path>
          </svg>
        </div>

        <main class="content-wrapper ">
          <section class="wrapper bg-soft-primary" style={{height:'100vh'}}>
            <div class="container ">
              <div class="row gx-md-8 gx-xl-12 gy-10 align-items-center text-center text-lg-start">
                <div
                  class="col-lg-12 mt-5 mb-5"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                >
                  <h1 className="text-center">Schedule a Free Discovery Call</h1>
                  <InlineWidget url="https://calendly.com/tietech" />
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default Appointment;
