import React from "react";

const Process = () => {
  const dotStyle = {
    transform: "scaleY(-1)",
    // backgroundColor: "green",
    width: "68px",
    borderRadius: "5px",
  };
  const dotStyleTwo = {
    transform: "scaleY(1)",
    // backgroundColor: "green",
    width: "72px",
    borderRadius: "5px",
  };
  return (
    <div>
      <div className="desktop-flow">
        <div className="process-main pt-5 pb-5">
          <div
            className="div-boxing"
            data-aos="fade-up"
            data-aos-duration="500"
          >
            <h2 className="text-center">
              Requirement <br></br> Gathering
            </h2>
            {/* <h2 className="text-center">1</h2> */}
            <div className="box-process"></div>
            <div className="dot" style={dotStyle}></div>
          </div>
          <div
            className="div-boxing-two"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            <h3 className="text-center">Analysis</h3>
            {/* <h3 className="text-center">2</h3> */}
            <div className="box-process"></div>
            <div className="dot-two" style={dotStyleTwo}></div>
          </div>
          <div
            className="div-boxing"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <h6 className="text-center">Design</h6>
            {/* <h2 className="text-center">3</h2> */}
            <div className="box-process"></div>
            <div className="dot" style={dotStyle}></div>
          </div>
          <div
            className="div-boxing-two"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <h1 className="text-center">
              Product <br></br> Backlog
            </h1>
            {/* <h3 className="text-center">4</h3> */}
            <div className="box-process"></div>
            <div className="dot-two" style={dotStyleTwo}></div>
          </div>
          <div
            className="div-boxing"
            data-aos="fade-up"
            data-aos-duration="1800"
          >
            <h4 className="text-center">Development</h4>
            {/* <h2 className="text-center">5</h2> */}
            <div className="box-process"></div>
            <div className="dot" style={dotStyle}></div>
          </div>
          <div
            className="div-boxing-two"
            data-aos="fade-up"
            data-aos-duration="2200"
          >
            <h3 className="text-center">Testing</h3>
            {/* <h3 className="text-center">6</h3> */}
            <div className="box-process"></div>
            <div className="dot-two" style={dotStyleTwo}></div>
          </div>
          <div
            className="div-boxing"
            data-aos="fade-up"
            data-aos-duration="2500"
          >
            <h4 className="text-center">Deployment</h4>
            {/* <h2 className="text-center">7</h2> */}
            <div className="box-process"></div>
            <div className="dot" style={dotStyle}></div>
          </div>
          <div
            className="div-boxing-two"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <h5 className="text-center">Maintainance</h5>
            {/* <h3 className="text-center">8</h3> */}
            <div className="box-process"></div>
            {/* <div className="dot-two" style={dotStyleTwo}></div> */}
          </div>
        </div>
      </div>
      <div className="mobile-flow">
        <div className="row">
          <div className="col-lg-6">
            <div className="process-main pt-5 pb-5">
              <div
                className="div-boxing"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                <h2 className="text-center">
                  Requirement <br></br> Gathering
                </h2>
                {/* <h2 className="text-center">1</h2> */}
                <div className="box-process"></div>
                <div className="dot" style={dotStyle}></div>
              </div>
              <div
                className="div-boxing-two"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <h3 className="text-center">Analysis</h3>
                {/* <h3 className="text-center">2</h3> */}
                <div className="box-process"></div>
                <div className="dot-two" style={dotStyleTwo}></div>
              </div>
              <div
                className="div-boxing"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <h6 className="text-center">Design</h6>
                {/* <h2 className="text-center">3</h2> */}
                <div className="box-process"></div>
                <div className="dot" style={dotStyle}></div>
              </div>
              <div
                className="div-boxing-two"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <h1 className="text-center">
                  Product <br></br> Backlog
                </h1>
                {/* <h3 className="text-center">4</h3> */}
                <div className="box-process"></div>
                <div className="dot-two" style={dotStyleTwo}></div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="process-main pt-5 pb-5">
              <div
                className="div-boxing"
                data-aos="fade-up"
                data-aos-duration="1800"
              >
                <h4 className="text-center">Development</h4>
                {/* <h2 className="text-center">5</h2> */}
                <div className="box-process"></div>
                <div className="dot" style={dotStyle}></div>
              </div>
              <div
                className="div-boxing-two"
                data-aos="fade-up"
                data-aos-duration="2200"
              >
                <h3 className="text-center">Testing</h3>
                {/* <h3 className="text-center">6</h3> */}
                <div className="box-process"></div>
                <div className="dot-two" style={dotStyleTwo}></div>
              </div>
              <div
                className="div-boxing"
                data-aos="fade-up"
                data-aos-duration="2500"
              >
                <h4 className="text-center">Deployment</h4>
                {/* <h2 className="text-center">7</h2> */}
                <div className="box-process"></div>
                <div className="dot" style={dotStyle}></div>
              </div>
              <div
                className="div-boxing-two"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <h5 className="text-center">Maintainance</h5>
                {/* <h3 className="text-center">8</h3> */}
                <div className="box-process"></div>
                {/* <div className="dot-two" style={dotStyleTwo}></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="small-flow">
        <div className="row">
          <div className="col-lg-6">
            <div className="process-main pt-2 pb-2">
              <div
                className="div-boxing"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                <h2 className="text-center">
                  Requirement <br></br> Gathering
                </h2>
                {/* <h2 className="text-center">1</h2> */}
                <div className="box-process"></div>
                <div className="dot" style={dotStyle}></div>
              </div>
              <div
                className="div-boxing-two"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <h3 className="text-center">Analysis</h3>
                {/* <h3 className="text-center">2</h3> */}
                <div className="box-process"></div>
                <div className="dot-two" style={dotStyleTwo}></div>
              </div>
            </div>
          </div>
         
          <div className="col-lg-6">
            <div className="process-main pt-2 pb-2">
              <div
                className="div-boxing"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <h6 className="text-center">Design</h6>
                {/* <h2 className="text-center">3</h2> */}
                <div className="box-process"></div>
                <div className="dot" style={dotStyle}></div>
              </div>
              <div
                className="div-boxing-two"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <h1 className="text-center">
                  Product <br></br> Backlog
                </h1>
                {/* <h3 className="text-center">4</h3> */}
                <div className="box-process"></div>
                <div className="dot-two" style={dotStyleTwo}></div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="process-main pt-2 pb-2">
              <div
                className="div-boxing"
                data-aos="fade-up"
                data-aos-duration="1800"
              >
                <h4 className="text-center">Development</h4>
                {/* <h2 className="text-center">5</h2> */}
                <div className="box-process"></div>
                <div className="dot" style={dotStyle}></div>
              </div>
              <div
                className="div-boxing-two"
                data-aos="fade-up"
                data-aos-duration="2200"
              >
                <h3 className="text-center">Testing</h3>
                {/* <h3 className="text-center">6</h3> */}
                <div className="box-process"></div>
                <div className="dot-two" style={dotStyleTwo}></div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="process-main pt-2 pb-2">
              <div
                className="div-boxing"
                data-aos="fade-up"
                data-aos-duration="2500"
              >
                <h4 className="text-center">Deployment</h4>
                {/* <h2 className="text-center">7</h2> */}
                <div className="box-process"></div>
                <div className="dot" style={dotStyle}></div>
              </div>
              <div
                className="div-boxing-two"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <h5 className="text-center">Maintainance</h5>
                {/* <h3 className="text-center">8</h3> */}
                <div className="box-process"></div>
                {/* <div className="dot-two" style={dotStyleTwo}></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
