import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
function Offcanvasexample({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const scrollToHomeSection = (sectionId) => {
    const Section = document.getElementById(sectionId);
    if (Section) {
      Section.scrollIntoView({ behavior: "smooth" });
      handleClose();
    }
  };
  return (
    <>
      <div>
        <img
          onClick={handleShow}
          className="menu"
          width={25}
          src="/img/menu.png"
          alt="menu"
        />
      </div>

      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Link to="/">
              <img width={200} alt="logo" src="/img/logoss.png" />
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div class="offcanvas-body ms-lg-auto d-flex flex-column h-100">
            <ul class="navbar-nav">
              <li class="nav-item dropdown dropdown-mega">
                <Link
                  to="#home"
                  class="nav-link"
                  onClick={() => scrollToHomeSection("home")}
                >
                  Home
                </Link>
              </li>
              <li class="nav-item dropdown">
                <Link
                  to="#home"
                  data-bs-toggle="dropdown"
                  class="nav-link "
                  onClick={() => scrollToHomeSection("home")}
                >
                  About Us
                </Link>
              </li>
              <li class="nav-item dropdown">
                <Link
                  to="#Whatwedo"
                  data-bs-toggle="dropdown"
                  class="nav-link "
                  onClick={() => scrollToHomeSection("Whatwedo")}
                >
                  What we do
                </Link>
              </li>
              <li class="nav-item dropdown">
                <Link
                  to="#choose-us"
                  data-bs-toggle="dropdown"
                  class="nav-link "
                  onClick={() => scrollToHomeSection("choose-us")}
                >
                  Choose Us
                </Link>
              </li>
              <li class="nav-item dropdown">
                <Link
                  to="#solution"
                  data-bs-toggle="dropdown"
                  class="nav-link "
                  onClick={() => scrollToHomeSection("solution")}
                >
                  Solution
                </Link>
              </li>
              <li class="nav-item dropdown dropdown-mega">
                <Link
                  to="#contact-us"
                  data-bs-toggle="dropdown"
                  class="nav-link "
                  onClick={() => scrollToHomeSection("contact-us")}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Offcanvasexample;
