import React from "react";
import Offcanvasexample from "./Offcanvas";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Header = () => {
  const scrollToHomeSection = (sectionId) => {
    const Section = document.getElementById(sectionId);
    if (Section) {
      Section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="sticky-top">
      <header class="wrapper bg-soft-primary sticky-top">
        <div style={{ paddingTop: "0px" }}></div>
        <nav class="navbar navbar-expand-lg center-nav transparent navbar-light d-none d-lg-block sticky-top">
          <div class="container flex-lg-row flex-nowrap align-items-center justify-content-between sticky-top">
            <div class="navbar-brand w-100 d-flex align-items-center justify-content-between ">
              <div>
                <Link to="/">
                  <img width={200} alt="logo" src="/img/logoss.png" />
                </Link>
              </div>
              <div>
                {["end"].map((placement, idx) => (
                  <Offcanvasexample
                    key={idx}
                    placement={placement}
                    name={placement}
                  />
                ))}
              </div>
            </div>
            <div
              id="offcanvas-nav"
              data-bs-scroll="true"
              class="navbar-collapse offcanvas offcanvas-nav offcanvas-start"
            >
              <div class="offcanvas-body ms-lg-auto d-flex flex-column h-100">
                <ul class="navbar-nav">
                  <li class="nav-item dropdown dropdown-mega">
                    <Link
                      to="#home"
                      class="nav-link"
                      onClick={() => scrollToHomeSection("home")}
                    >
                      Home
                    </Link>
                  </li>
                  <li class="nav-item dropdown">
                    <Link
                      to="#about-us"
                      data-bs-toggle="dropdown"
                      class="nav-link "
                      onClick={() => scrollToHomeSection("about-us")}
                    >
                      About Us
                    </Link>
                  </li>
                  <li class="nav-item dropdown">
                    <Link
                      to="#Whatwedo"
                      data-bs-toggle="dropdown"
                      class="nav-link "
                      onClick={() => scrollToHomeSection("Whatwedo")}
                    >
                      What we do
                    </Link>
                  </li>
                  <li class="nav-item dropdown">
                    <Link
                      to="#choose-us"
                      data-bs-toggle="dropdown"
                      class="nav-link "
                      onClick={() => scrollToHomeSection("choose-us")}
                    >
                      Choose Us
                    </Link>
                  </li>
                  <li class="nav-item dropdown">
                    <Link
                      to="#solution"
                      data-bs-toggle="dropdown"
                      class="nav-link "
                      onClick={() => scrollToHomeSection("solution")}
                    >
                      Solution
                    </Link>
                  </li>
                  <li class="nav-item dropdown dropdown-mega">
                    <Link
                      to="#contact-us"
                      data-bs-toggle="dropdown"
                      class="nav-link "
                      onClick={() => scrollToHomeSection("contact-us")}
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="navbar-other w-100 d-flex ms-auto">
              <ul class="navbar-nav flex-row align-items-center ms-auto">
                <li class="nav-item d-none d-lg-block">
                  <Link to="/appointment" class="btn  custom-btn">
                    Schedule a Call
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* Mobile Header start */}
        <div
          className="container pt-3 d-flex align-items-center justify-content-between d-block d-lg-none "
          style={{ cursor: "pointer" }}
        >
          <div>
            <Link to="/">
              <img width={200} alt="logo" src="/img/logoss.png" />
            </Link>
          </div>
          <div className=" d-flex align-items-center ">
            <Link to="/appointment" class="btn schecule  custom-btn mx-3">
              Schedule a Call
            </Link>
            {["end"].map((placement, idx) => (
              <Offcanvasexample
                key={idx}
                placement={placement}
                name={placement}
              />
            ))}
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
